export enum RouteKeys {
  Home = '/',
  ForgotPassword = '/forgot-password',
  NotFound = '/page-not-found',
  SearchResult = '/search-result',
  Checkout = '/checkout',
  MyOrder = '/my-order/:status?',
  MyAccount = '/my-account',
  UserManagement = '/my-account/user-management',
  FAQ = '/faq',
  BundleOffer = '/bundle-offer/:id?',
  Offer = '/offers',
  BannerOffer = '/banner-offer/:id?',
  PrivacyPolicy = '/privacy-policy',
  Product = '/product/:id',
}
