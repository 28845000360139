import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import { Product } from '../../models/product';
import { CartItem, CheckoutSubmit } from '../../models';

const serviceEndpoint = 'cart';

export default class CartService extends ApiService {
  public async AddItemtoCart(product: Product, cartID?: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/addItem`;
    const data = new FormData();
    data.append(
      'productID',
      product.productID ? product.productID.toString() : ''
    );
    data.append(
      'productOptionID',
      product.productOptionID ? product.productOptionID.toString() : ''
    );
    data.append(
      'quantity',
      product.quantity ? product.quantity.toString() : '1'
    );
    if (cartID) {
      data.append('cartID', cartID ? cartID.toString() : '');
    }

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
  public async updateQuantity(item: CartItem, quantity: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/updateQuantity`;
    const data = new FormData();

    data.append(
      'productOptionID',
      item.productOptionID ? item.productOptionID.toString() : ''
    );
    data.append('quantity', quantity ? quantity.toString() : '');
    data.append('cartID', item.cartID ? item.cartID.toString() : '');
    data.append(
      'cartItemID',
      item.cartItemID ? item.cartItemID.toString() : ''
    );

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async bulkOrder(
    product: Product,
    quantity: number,
    cartID?: number,
    cartItemID?: number
  ) {
    const apiUrl = cartItemID ? 'updateQuantity' : 'addItem';

    const url = `${this.apiDomain}/${serviceEndpoint}/${apiUrl}`;
    const data = new FormData();
    data.append(
      'productID',
      product.productID ? product.productID.toString() : ''
    );
    data.append(
      'productOptionID',
      product.productOptionID ? product.productOptionID.toString() : ''
    );
    if (cartID) {
      data.append('cartID', cartID ? cartID.toString() : '');
    }
    if (cartItemID) {
      data.append('cartItemID', cartItemID ? cartItemID.toString() : '');
    }
    data.append('quantity', quantity ? quantity.toString() : '1');

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
  public async removeCart(item: CartItem) {
    const url = `${this.apiDomain}/${serviceEndpoint}/updatecart`;
    const data = new FormData();

    data.append('cartID', item.cartID ? item.cartID.toString() : '');
    data.append(
      'cartItemID',
      item.cartItemID ? item.cartItemID.toString() : ''
    );
    data.append('mode', 'delete_item');
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
  public async applyPromoCode(promoCode: string, cartID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/addpromo`;
    const data = new FormData();

    data.append('cartID', cartID ? cartID.toString() : '0');
    data.append('promoCode', promoCode);

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
  public async removePromoCode(cartID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/removepromo`;
    const data = new FormData();

    data.append('cartID', cartID ? cartID.toString() : '0');

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async getCheckoutDetails(cartID: number) {
    const data = new FormData();
    data.append('cartID', cartID ? cartID.toString() : '0');

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    const url = `${this.apiDomain}/${serviceEndpoint}/checkout`;

    return http.postAsFormData(url, data);
  }

  public async getCart() {
    const data = new FormData();
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    const url = `${this.apiDomain}/${serviceEndpoint}/index`;
    return http.postAsFormData(url, data);
  }

  public async updateAddress(userShippingID: number, cartID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/updateaddress`;
    const data = new FormData();

    data.append('userShippingID', userShippingID.toString());
    data.append('cartID', cartID.toString());

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async setPaymentFee(paymentSelected: string, cartID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/setPaymentFee`;
    const data = new FormData();

    data.append('paymentSettingsGroupKeySelected', paymentSelected);
    data.append('cartID', cartID.toString());

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }

  public async checkoutSubmit(item: CheckoutSubmit) {
    const url = `${this.apiDomain}/${serviceEndpoint}/checkoutsubmit`;
    const data = new FormData();

    data.append('cartID', item.cartID.toString());
    data.append(
      'paymentSettingsGroupKeySelected',
      item.paymentSettingsGroupKeySelected
    );
    data.append('selfPickup', item.selfPickup);
    // if (item.selfPickup === 'Yes') {
    data.append('shippingContactName', item.shippingContactName);
    data.append('shippingContactPhone', item.shippingContactPhone);
    data.append(
      'shippingContactPhoneCountryCode',
      item.shippingContactPhoneCountryCode
    );
    data.append(
      'shippingContactPhoneDialCode',
      item.shippingContactPhoneDialCode
    );
    // }

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (item.purchaseFile) {
      data.append('purchaseFile', item.purchaseFile);
    }
    if (item.locationID) {
      data.append(
        'locationID',
        item.locationID ? item.locationID.toString() : '0'
      );
    }
    if (item.poDate) {
      data.append('poDate', item.poDate);
    }
    if (item.poNumber) {
      data.append('poNumber', item.poNumber);
    }
    if (item.cardNumber) {
      data.append('cardNumber', item.cardNumber);
    }
    if (item.nameOnCard) {
      data.append('nameOnCard', item.nameOnCard);
    }
    if (item.cvv) {
      data.append('cvv', item.cvv);
    }
    if (item.expiryYear) {
      data.append('expiryYear', item.expiryYear);
    }
    if (item.expiryMonth) {
      data.append('expiryMonth', item.expiryMonth);
    }
    if (item.comments) {
      data.append('comments', item.comments);
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');

    return http.postAsFormData(url, data);
  }
}
